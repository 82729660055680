body, *{
  margin: 0;
  padding: 0;
}
body {
  background: #F4F3EE;
  position: relative;
}
li{
  list-style: none;
}

@font-face {
  font-family: 'halyard-medium';
  src: url(../fonts/halyard/Halyard-Micro-Medium.ttf);
}
@font-face {
  font-family: 'halyard-light';
  src: url(../fonts/halyard/Halyard-Micro-Light.ttf);
}
@font-face {
  font-family: 'halyard-regular';
  src: url(../fonts/halyard/Halyard-Text-Regular.ttf);
}
@font-face {
  font-family: 'instrument-medium';
  src: url(../fonts/instrument/InstrumentSans-Medium.ttf);
}
@font-face {
  font-family: 'instrument-regular';
  src: url(../fonts/instrument/InstrumentSans-Regular.ttf);
}

h1 {
  font-family: 'halyard-light';
  font-weight: 600;
  margin: 0;
  font-size: 3rem;
  line-height: 3.5rem;
  letter-spacing: -0.2rem;
}
h4 {
  font-family: 'instrument-medium';
  font-size: 0.8rem;
  font-weight: 100;
  color: #333333;
}
h6 {
  font-family: 'instrument-regular';
  font-weight: 100;
  font-size: 0.65rem;
  letter-spacing: -0.02rem;
  color: #848382;
}
h6.f-20 {
  font-size: 0.9rem;
  color: #000;
  font-family: 'instrument-medium';
}
.container{
  max-width: 1920px;
  margin: auto;
}
.padding-x-all{
  padding: 0 6.8rem;
}
.sec-com-padding{
  padding: 6.5rem 0;
}
.relative{
  position: relative;
}
.flex-box{
  display: flex;
  flex-wrap: wrap;
}
.space-between{
  justify-content: space-between;
}
.align-center{
  align-items: center;
}
.selected {
  background: #F9F92F !important;
}
.selected h6 {
  color: #000;
}
.edit-btn-box {
  text-align: end;
  margin-right: -0.2rem;
  margin-top: -0.9rem;
}
.edit-btn-box img {
  width: 0.8rem;
  height: 1.1rem;
  object-fit: contain;
  background: #F9F92F;
  padding: 0.22rem 0.4rem;
  border-radius: 5rem;
  cursor: pointer;
}
.customer-name-box .img {
  width: 2.1rem;
  height: 2.1rem;
  object-fit: cover;
  margin-right: 0.4rem;
  border-radius: 5rem;
  box-shadow:0 0px 1px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
  display: flex ;
  justify-content: center;
  font-size: 18px;
  font-family: 'instrument-medium';
  align-items: center;
  color: white;
  background-color: black;
  padding: 2px;
}
.customer-name-box h4 {
  font-size: 0.8rem;
}
.customer-name-box h6 {
  font-size: 0.55rem;
  margin-bottom: 0.2rem;
}
.customer-detail-box {
  margin-top: 0.5rem;
}
.customer-contact-detail-box h4 {
  display: flex;
  margin: 0;
  font-size: 0.55rem;
  filter: opacity(0.5);
  padding: 0.8rem 0;
  background: radial-gradient(54% 2% at 50% 0%, #acacac 0%, rgba(80, 28, 28, 0) 100%);
}
.customer-contact-detail-box h4 span {
  margin-right: 0.4rem;
  display: block;
}
.customer-contact-detail-box img {
  width: 0.7rem;
  height: 0.7rem;
  object-fit: contain;
}
.customer-contact-detail-box h6 {
  background: #000;
  width: max-content;
  padding: 0.05rem 0.4rem;
  color: #fff;
  border-radius: 2rem;
  font-size: 0.45rem;
  letter-spacing: 0;
  margin-left: 1rem;
  margin-bottom: 0.2rem;
}
.customer-contact-detail-box {
  padding-bottom: 1rem;
  margin-bottom: 0.6rem;
  background: radial-gradient(50% 0.5% at 50% 95%, #d3d3d3 0%, rgba(80, 28, 28, 0) 100%);
}
.customer-name-box.flex-box.align-center {
  margin-bottom: 1.1rem;
}

.main-menu-box {
  background: #FFFFFF;
  padding: 1.7rem 1rem;
  border-radius: 1.3rem;
  width: 11.5%;
  min-height: 39rem;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-bottom: 1.4rem;
}
.logout-btn {
  text-align: center;
  font-family: 'halyard-medium';
  font-weight: 100;
  font-size: 0.6rem;
  background: #F4F3EE;
  padding: 0.7rem 0;
  border-radius: 0.3rem;
  cursor: pointer;
}
.logout-btn:hover {
  background: #ededed;
}
.main-dashboard-box {
  padding: 2rem;
}
ul.menu-listing li .tab-box {
  padding: 0.6rem 0.8rem;
  border-radius: 0.5rem;
  z-index: 10;
  position: relative;
  cursor: pointer;
  margin-bottom: 0.2rem;
}
ul.menu-listing li .tab-box:hover {
  background: #F4F3EE;
}
ul.menu-listing li .tab-box.selected, ul.menu-listing li .tab-box.pre-selected {
  background: #F9F92F !important;
}
ul.menu-listing li .tab-box.grey-bg {
  background: #F4F3EE;
}
ul.menu-listing li .tab-box.selected h6, ul.menu-listing li .tab-box.grey-bg h6, ul.menu-listing li .tab-box.pre-selected h6 {
  color: #000;
  font-weight: 600;
}
ul.menu-listing li .tab-box.selected h6 img, ul.menu-listing li .tab-box.grey-bg h6 img, ul.menu-listing li .tab-box.pre-selected h6 img{
  filter: opacity(1);
}
ul.menu-listing li .tab-box img {
  object-fit: contain;
  width: 1rem;
  height: 1rem;
  filter: opacity(0.5);
}
ul.menu-listing li .tab-box h6 {
  display: flex;
  align-items: center;
  color: #2422208F;
  font-family: 'instrument-medium';
}
ul.menu-listing li .tab-box h6 span {
  margin-right: 0.8rem;
}
h6.menu-sub-text {
  text-transform: uppercase;
  font-size: 0.55rem;
  margin-bottom: 0.4rem;
  margin-left: 0.6rem;
}
.tab-detail-outer-box {
  width: 83.8%;
}
.payout-head-box select {
  background: transparent;
  border: none;
  font-family: 'instrument-medium';
  color: #333333;
  cursor: pointer;
  font-size: 0.8rem;
}
.payout-head-box h4 {
  margin-right: 1.2rem;
  padding-right: 1.2rem;
  border-right: 1px solid;
}
.payout-head-box select option {
  background: #fff;
  color: #333333;
  font-size: 0.8rem;
  font-family: 'instrument-medium';
}
.lifetime-data {
  background: #fff;
  padding: 0.8rem;
  margin-bottom: 1.2rem;
  border-radius: 0.4rem;
  margin-right: 1.2rem;
  width: 9rem;
}
.lifetime-data h6 {
  font-size: 0.75rem;
}
.lifetime-data h6.right {
  text-align: end;
  margin-bottom: 1.2rem;
}
.lifetime-data h4 {
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
  font-weight: 700;
}
h6.tab-detail-heading {
  font-weight: 600;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  margin-top: 1.5rem;
  margin-bottom: 1.8rem;
  letter-spacing: 0;
}
ul.all-jobs-listing li .job-box:hover {
  background: #fbfbfb;
}
ul.all-jobs-listing li .job-box h6 {
  margin-bottom: 0.3rem;
}
ul.all-jobs-listing li .job-box {
  background: #fff;
  padding: 1.4rem 1.2rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  text-decoration: none;
}
ul.all-jobs-listing li .job-box .job-list-inner-box {
  border-right: 1px solid #D9D9D9;
  margin-right: 1.5rem;
}
ul.all-jobs-listing li .job-box .job-list-inner-box:first-child {
  width: 6%;
}
ul.all-jobs-listing li .job-box .job-list-inner-box:nth-child(2) {
  width: 18%;
}
ul.all-jobs-listing li .job-box .job-list-inner-box:nth-child(3) {
  width: 18%;
}
ul.all-jobs-listing li .job-box .job-list-inner-box:nth-child(4) {
  width: 13.5%;
}
ul.all-jobs-listing li .job-box .job-list-inner-box:nth-child(5) {
  width: 12.5%;
}
ul.all-jobs-listing li .job-box .job-list-inner-box:nth-child(6) {
  width: 10%;
}
ul.all-jobs-listing li .job-box .job-list-inner-box:nth-child(6) h4 {
  font-weight: 700;
}
ul.all-jobs-listing li .job-box .job-list-inner-box:nth-child(7){
  border: none;
  width: 14%;
  margin-right: 0;
}
ul.all-jobs-listing li .job-box .job-list-inner-box:nth-child(7) h4{
  position: relative;
  width: max-content;
}

.status-dot {
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -15%;
  right: -9%;
}
.orange {
  background: #FFC738;
  box-shadow: 0px 0px 6px 0px #FFC738;
}
.green {
   background: #1BD038;
   box-shadow: 0px 0px 6px 0px #1BD038;
}
.red{
  background: #FF3838;
  box-shadow: 0px 0px 6px 0px #FF3838;
}

.order-cta-box img {
  width: 1.3rem;
  object-fit: contain;
}
.order-cta-box {
  width: 2.2rem;
  height: 2.2rem;
  background: #f9f92f;
  border-radius: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-inner-listing {
  position: relative;
  padding-left: 2rem;
  height: 0;
  overflow: hidden;
  margin: 0;
}
.tab-box.hide+div {
  height: 0;
  padding-top: 0;
}
.menu-inner-listing.full-height {
  height: 100%;
  padding-top: 0.2rem;
}
.menu-inner-tab-box {
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
  margin: 0.1rem 0;
  cursor: pointer;
}
.menu-inner-tab-box h6 {
  font-family: 'instrument-medium';
  color: #2422208F;
}
.menu-inner-tab-box.selected h6{
  color: #000;
}
.menu-inner-tab-box:hover{
  background-color: #F4F3EE;
}
.menu-inner-listing::after{
  content: '';
  position: absolute;
  background: url(../img/connection-icon.webp);
  background-repeat: no-repeat;
  background-size: contain;
  left: 10%;
  top: -1%;
  width: 1.5rem;
  height: 87%;
}
.menu-inner-listing.menu-inner-listing-2::after{
  content: '';
  position: absolute;
  background: url(../img/connection-icon-2.webp);
  background-repeat: no-repeat;
  background-size: contain;
  left: 10%;
  top: -1%;
  width: 1.5rem;
  height: 87%;
}
.job-detail-outer-box {
  width: 83.7%;
}
.job-detail-box {
  width: 81%;
}
.job-info-box {
  padding: 1.2rem 1.4rem;
  background: #fff;
  border-radius: 0.5rem;
}
.job-info-box .head-box {
  margin-bottom: 1.5rem;
}
.job-info-box h6.tab-detail-heading {
  font-size: 0.9rem;
  font-family: 'instrument-regular';
  margin: 0;
}
.job-info-box h6.tab-detail-heading img {
  width: 0.7rem;
  margin-right: 0.4rem;
}
ul.job-info-listing li {
  width: max-content;
  display: flex;
  flex-flow: column;
}
ul.job-info-listing li .text-box {
  margin: 0;
  border-right: 1px solid #D9D9D9;
  padding-right: 1.4rem;
}
ul.job-info-listing li:last-child .text-box{
  padding-right: 0;
  border-right: 0;
}
ul.job-info-listing li:last-child .text-box {
  padding-right: 0.8rem;
  border-right: 0;
}
ul.job-info-listing li .text-box h6 {
  margin-bottom: 0.4rem;  
  max-width: fit-content;
}
li.item-detail-box {
  /* margin: 1rem 0; */
  border-radius: 0.5rem;
  background: #fff;
}
li.item-detail-box .head-box h6 img {
  margin-left: 1rem;
  width: 0.9rem;
  object-fit: contain;
  transition: 0.3s all;
}
li.item-detail-box .head-box h6 img.rotate{
  transform: rotateZ(180deg);
  transition: 0.3s all;
}
ul.item-detail-inner-box-listing {
  margin: 0.7rem 0rem;
  margin-bottom: 0;
  padding: 1.2rem 1.8rem;
  padding-top: 0;
  transition: 0.3s all;
}

li.item-detail-inner-box .text-box {
  width: 60%;
}
li.item-detail-inner-box .img-box {
  width: 32.5%;
  margin-top: -2.3rem;
}
li.item-detail-inner-box .img-box h6{
  margin-bottom: 0.7rem;
}
li.item-detail-inner-box video, li.item-detail-inner-box img {
    width: 8.2rem;
    height: 11rem;
    object-fit: cover;
    border-radius: 0.8rem;
}
li.item-detail-inner-box .field-box {
  margin-bottom: 1.3rem;
}
li.item-detail-inner-box .field-box:last-child {
  margin-bottom: 0;
}
li.item-detail-inner-box .field-box h6 {
  margin-bottom: 0.3rem;
}
li.item-detail-inner-box .field-box h4 {
  line-height: 1.1rem;
  font-size: 0.76rem;
}
li.item-detail-inner-box .field-box h6.address {
  font-size: 0.7rem;
  margin-top: 0.3rem;
  width: 80%;
}
li.item-detail-inner-box .field-box video {
  width: 4rem;
  object-fit: contain;
}
li.item-detail-inner-box .field-box img {
  width: 3.5rem;
  object-fit: contain;
}
ul.items-listing li.item-detail-box ul.item-detail-inner-box-listing.show {
  display: none;
  transition: 0.3s all;
}
li.item-detail-box .head-box {
  cursor: pointer;
  padding: 1.35rem 1.8rem;
  padding-bottom: 0.5rem;
}
li.item-detail-box .head-box h6.f-20 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.13rem;
  letter-spacing: -0.005rem;
  -webkit-text-stroke-width: 0.02rem;
}
li.item-detail-inner-box .field-box .img-box img {
  object-fit: contain;
  width: 2.4rem;
  margin-right: .5rem;
}
li.item-detail-inner-box .field-box.item-amount-box .text-box {
  font-size: 0.3rem;
  color: #D9D9D9;
}
li.item-detail-inner-box .field-box.item-amount-box .text-box h4 {
  width: 30%;
  font-size: 0.6rem;
  text-align: end;
}
li.item-detail-inner-box .field-box.item-amount-box .text-box h6 {
  width: 35%;
  font-size: 0.5rem;
}
li.item-detail-inner-box .field-box.item-amount-box .text-head-box {
  margin-bottom: 0.2rem;
}
.job-side-detail-box {
  width: 16.5%;
}
.job-day-left-box{
  background: url(../img/days-left-bg.webp);
  object-fit: cover;
  padding: 1.2rem 1.2rem;
  padding-bottom: 2.2rem;
  text-align: center;
  border-radius: 1.2rem;
  background-position: top;
}  
.job-day-left-box h2{
  text-align: center;
  font-family: 'instrument-medium';
  font-size: 4.3rem;
}
.job-side-detail-box select {
  border: none;
  background: transparent;
  border: 1px solid;
  border-radius: 0.4rem;
  font-family: 'instrument-regular';
  font-size: 0.6rem;
  padding: 0.5rem 0.5rem;
  width: 100%;
  height: 2.2rem;
  margin-top: 1.2rem;
  cursor: pointer;
}
.job-side-detail-box select option{
  font-size: 0.8rem;
  font-family: 'instrument-regular';
}
.job-side-detail-box span {
  font-family: 'halyard-regular';
  font-style: italic;
  font-size: 0.65rem;
  font-weight: 100;
  float: right;
}
.job-day-left-box h4 {
  font-size: 0.7rem;
  margin-top: -0.4rem;
}
.job-day-left-box h6 {
  text-align: left;
  background: #f8f92e;
  width: max-content;
  margin-top: 1.3rem;
  margin-bottom: -1.6rem;
  z-index: 10;
  position: relative;
  margin-left: 0.6rem;
  padding: 0 0.3rem;
  color: #000;
  font-weight: 700;
  font-size: 0.55rem;
  letter-spacing: 0;
}
.item-picked-box h3 {
  font-family: 'instrument-regular';
  font-size: 0.9rem;
}
.item-picked-box {
  padding: 1.5rem 1.2rem;
  padding-bottom: 2.2rem;
  background: #fff;
  border-radius: 1.2rem;
  margin-top: 1.3rem;
}


/* payouts css */

h6.tab-detail-heading.margin-top-zero {
  margin-top: 0;
  margin-bottom: 1rem;
}
ul.all-payouts-listing.all-jobs-listing li .job-box .job-list-inner-box:first-child {
  width: 14%;
}
ul.all-payouts-listing.all-jobs-listing li .job-box .job-list-inner-box:nth-child(2) {
  width: 18%;
}
ul.all-payouts-listing.all-jobs-listing li .job-box .job-list-inner-box:nth-child(3) {
  width: 25%;
}
ul.all-payouts-listing.all-jobs-listing li .job-box .job-list-inner-box:nth-child(4) {
  width: 5.8%;
}
ul.all-payouts-listing.all-jobs-listing li .job-box .job-list-inner-box:nth-child(5) {
  width: 20.5%;
  border: none;
}
.popup-outer-box {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000b3;
}
.popup-box {
  background: #fff;
  padding: 1.2rem;
  border-radius: 0.6rem;
  position: relative;
}
button.popup-close-btn img {
  width: 0.8rem;
  height: 0.7rem;
  margin-bottom: -0.1rem;
  object-fit: contain;
}
button.popup-close-btn {
  position: absolute;
  right: -1rem;
  top: -1rem;
  cursor: pointer;
  margin: 0;
  border: none;
  background: #E2E21A;
  width: 2rem;
  height: 2rem;
  border-radius: 5rem;
}
h4.popup-inner-heading {
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #D9D9D9;
  font-family: 'instrument-regular';
  font-weight: 600;
}
.popup-inner-list-box {
  padding: 0.8rem 0;
  margin-bottom: 0.8rem;
  border-bottom: 1px solid #D9D9D9;
}
.popup-inner-list-box .field-box {
  margin-right: 1rem;
}
.popup-inner-list-box .field-box:last-child {
  margin-right: 0;
}
.popup-box h6 {
  margin-bottom: 0.3rem;
}
.popup-box .field-box.border-bottom {
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 0.8rem;
}
.popup-payout-list-box {
  margin-top: 0.8rem;
  background: #F5F5F5;
  padding: 0.9rem 1rem;
  border-radius: 0.5rem;
}
.popup-payout-list-box h4.popup-inner-heading {
  border-bottom: 0;
}
a.popup-concern-btn {
  display: flex;
  color: #333333;
  align-items: center;
  text-decoration: none;
  border-bottom: 1px solid #333333;
  width: max-content;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
}
a.popup-concern-btn img {
  width: 0.7rem;
  margin-right: 0.15rem;
}
a.popup-concern-btn h6 {
  color: #333333;
  margin-bottom: 0.15rem;
  font-family: 'instrument-medium';
}
/* user edit css  */

.user-edit-popup {
  padding: 2.2rem 1.5rem;
}
.img-change-box {
  width: 30%;
}
.img-change-box img.user-edit-img {
  width: 6rem;
  height: 6rem;
  margin-right: 2.7rem;
  object-fit: cover;
  border-radius: 10rem;
}
.img-change-box img.user-change-img {
  position: absolute;
  width: 1.2rem;
  top: 5%;
  right: 5%;
  object-fit: contain;
  cursor: pointer;
}
.user-text-box {
  width: 57%;
}
.user-text-box .field-box {
  margin-bottom: 0.8rem;
}
.user-text-box .field-box h4 {
  font-size: 0.65rem;
}
.user-text-box .input-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #DBDBDB;
  margin-top: 0.5rem;
}
.user-text-box .input-box input {
  border: none;
  font-size: 0.65rem;
  font-family: 'instrument-regular';
  padding: 0.5rem 0.3rem;
  width: 71%;
  border-radius: 0.2rem;
}
.user-text-box .input-box button {
  background: transparent;
  border: none;
  font-family: 'instrument-medium';
  font-size: 0.62rem;
  padding-right: 0.5rem;
  cursor: pointer;
}
.address-edit-box input {
  font-family: 'instrument-regular';
  font-size: 0.65rem;
  width: 100%;
  padding: 0.5rem 0.3rem;
  border: 1px solid #DBDBDB;
  border-radius: 0.2rem;
}
button.save-info-btn {
  background: #E2E21A;
  border: none;
  padding: 0.5rem 0.2rem;
  margin-top: 1.5rem;
  border-radius: 2rem;
  font-size: 0.7rem;
  width: 5rem;
  font-family: 'instrument-medium';
  cursor: pointer;
}

/* login css  */


.login-main-box h2 {
  font-family: 'halyard-light';
  font-weight: 600;
  margin-bottom: 0.8rem;
  font-size: 2.1rem;
  line-height: 3.5rem;
  letter-spacing: -0.2rem;
}
.login-main-box p {
  font-family: 'instrument-regular';
  font-weight: 100;
  font-size: 1rem;
  line-height: 1.1rem;
  letter-spacing: 0rem;
}
.login-right-box .form-box .feild-box, .login-right-box .otp-box .feild-box {
  margin-top: 1rem;
  flex-flow: column;
}
.form-box .feild-box input, input.otp-input {
  padding: 0.5rem 0.3rem;
  padding-left: 0.8rem;
  font-family: 'instrument-regular';
  border: 1px solid #DBDBDB;
  border-radius: 0.4rem;
}
.show-otp-box {
  display: block !important;
}
.hide-form-box{
  display: none !important;
}

/* new edit for login  */

.login-left-box {
  background: url(../img/log-in-left-bg.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: end;
  height: 79vh;
  border-radius: 1rem;
  padding: 2rem 2.3rem;
  width: 38%;
  display: flex;
  flex-flow: column;
}

.login-main-box {
  padding: 2rem;
  height: max-content;
  position: relative;
}
.login-main-box::after{
  content: '';
  position: absolute;
  background: url(../img/login-after.webp);
  background-repeat: no-repeat;
  background-size: contain;
  right: 4%;
  top: 6%;
  width: 6rem;
  height: 3rem;
}
.login-left-box .text-box {
  background: linear-gradient(100.08deg, rgb(88 88 88 / 77%) -4.89%, rgb(99 99 99) 105.72%);
  box-shadow: 0px 26.36px 39.93px 0px #0000001A;
  padding: 2rem 1.8rem;
  padding-top: 1rem;
  border-radius: 1rem;
}
.login-main-box h2{
  font-size: 1.8rem;
}
.login-main-box p {
  font-size: 0.9rem;
  line-height: 1.3rem;
  color: #fff;
}
.login-main-box h2{
  color: #ffffff;
}
.login-right-box {
  width: 44%;
}
.login-right-box h1 {
  font-size: 1.6rem;
  -webkit-text-stroke-width: 0.01rem;
  letter-spacing: -0.1rem;
  line-height: 3rem;
}
.login-right-box h6 {
  font-size: 1.05rem;
}
.login-right-box .form-box .feild-box{
  align-items: start;
}
.form-box .feild-box input, input.otp-input {
  width: 65%;
  margin-top: 0.4rem;
  height: 1.8rem;
  background-color: transparent;
  font-size: 0.85rem;
}
.form-box .feild-box h4, .otp-box h4 {
  margin-top: 1rem;
  color: #5A5A5A;
}
.form-box button.save-info-btn, .otp-box button.save-info-btn {
  height: 3rem;
  border-radius: 0.5rem;
  width: 7.5rem;
  font-weight: 700;
  font-size: 0.86rem;
  font-family: 'halyard-medium';
  margin-top: 1.7rem;
}




@media all and (min-width: 1920px) {
  html {
    font-size: 26px;
  }
}   
@media all and (max-width: 1920px) {
  html {
    /* font-size: 1.4vw; */
    font-size: 1.15vw;
  }
}
@media all and (min-width: 767px) {
   .desktop-none{
     display: none !important;
   }
}  
@media all and (max-width: 767px) {
  html {
    /* font-size: 1.3vw; */
    font-size: 1.05vw;
  }
}
@media all and (max-width: 700px) {
  body * {
    display: none;
  }
  body {
    position: relative;
    display: block; 
    height: 100vh; 
    margin: 0;
    background: white; 
  }
  body::after {
    content: 'This dashboard is not for Mobile';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 95%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 7rem;
    line-height: 9rem;
    text-transform: capitalize;
    font-family: 'halyard-medium';
    text-align: center;
  }
}